<template>
  <div class="main">
    <van-nav-bar
        title="检查详情"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div class="main">
      <div class="jian"></div>
      <div class="block1">
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">被检查单位</div></van-col>
          <van-col span="17"><div class="you">{{data.storeName}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">企业负责人</div></van-col>
          <van-col span="17"><div class="you">{{data.contact}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">联系电话</div></van-col>
          <van-col span="17"><div class="you">{{data.phone}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">单位地址</div></van-col>
          <van-col span="17"><div class="you">{{data.address}}</div></van-col>
        </van-row>
      </div>
      <div class="jian"></div>
      <div class="block1">
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">预警类别</div></van-col>
          <van-col span="17"><div class="you">{{data.remark}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">检查类型</div></van-col>
          <van-col span="17"><div class="you">{{ctype(data.type)}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">检查结果</div></van-col>
          <van-col span="17"><div class="you">{{data.totalScore}}</div></van-col>
        </van-row>

      </div>
      <div class="jian"></div>
      <div class="block1">
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">检查时间</div></van-col>
          <van-col span="17"><div class="you">{{data.completeDate}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">检查人员</div></van-col>
          <van-col span="17"><div class="you">{{data.memberName}}</div></van-col>
        </van-row>

      </div>
      <div class="jian"></div>
    </div>

    <van-popup v-model="showPickerDate" position="bottom">
      <van-datetime-picker v-model="currentDate" type="datetime" @cancel="onCancelDate" @confirm="onConfirmDate" />
    </van-popup>
  </div>

</template>

<script>
import { getDetail,getType } from '@/api/get'
import Vue from 'vue';
import { NavBar,Col, Row,Icon,Cell,Popup,DatetimePicker } from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DatetimePicker);
export default {
  name: "xiafa",
  data(){
    return{
      data:{},
      id:0,
      showPickerDate: false,
      currentDate: new Date(),
    }},
  mounted() {
    this.id=this.$route.query.id
    this.loadData()
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
  },
  methods:{
    ctype(t){
      return getType(t)
    },
    loadData(){
      getDetail({id:this.id}).then(res => {
       this.data=res.data.data
      })
    },
    onConfirmDate (val) {
      console.log(val)
      this.onCancelDate()
    },
    onCancelDate () {
      this.showPickerDate = false
    },
    onClickLeft() {
      this.$router.push({ path: '/history', query: {id:1}})
    },
    showBeginDate () {
      this.showPickerDate = true
    },
  }
}
</script>

<style scoped lang="css" src="./assets/index.css" />